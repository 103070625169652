

// 添加轮播图
import {addBannerAdmin, updateBannerAdmin} from "@/Api/Platform/bascs";
import Storage from "@/util/Storage";
import {Toast} from "vant";
import {keyData} from "@/views/PlatformAdmin/AddNoticeAdmin/Model";
import {filterUrl} from "@/views/PlatformAdmin/AddNoticeAdmin/Util";
import {Data} from "@/views/PlatformAdmin/AddBannerAdmin/Model";

export function addBanner(body:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let keyData = {
        "title":"请输入标题",
        "subtitle":"请输入类目",
        "imageUrl":"请上传轮播图"
    },num = 0;
    Object.keys(keyData).forEach(item=>{
        if ( !body[item] ) {
            Toast(keyData[item]);
            num ++;
        }
    })
    if ( num ){
        Toast("请输入轮播内容")
        return Promise.reject()
    }
    Toast.loading({ duration:0,message:"添加中~~" })
    return Promise.resolve(addBannerAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}

// 修改轮播图
export function updateBanner(body:any = {},oldData:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    let num = 0;
    Data.forEach(item=>{
        if ( body[item] ) {
            if ( body[item] != oldData[item] ){
                num ++;
            }
        }
    })
    body["id"] = oldData["id"]

    if ( num <= 0 ){
        Toast("请修改公告内容")
        return Promise.reject()
    }
    Toast.loading({ duration:0,message:"修改中~~~" })
    return Promise.resolve(updateBannerAdmin({ userId,token },body).then(res=>{
        Toast.clear()
        return res.data
    }))
}
