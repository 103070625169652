


export const Data = [
    "title",
    "subtitle",
    "jumpUrl",
    "imageUrl",
    "description"
]
